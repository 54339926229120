// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Grid6, Notepad2, Bank, Category2, Courthouse, NoteAdd } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: Notepad2,
  icon2: Bank,
  icon3: Grid6,
  icon4: Category2,
  icon5: Courthouse,
  icon6: NoteAdd,
};
// ==============================|| MENU ITEMS - category ||============================== //

const superAdminQuestionBank: NavItemType = {
  id: 'superAdminQuestioBank',
  type: 'group',
  name: 'superAdminQuestioBank',
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: 'Exam',
      title: <FormattedMessage id="Exam Control" />,
      type: 'collapse',
      children: [
        {
          icon: icons.icon5,
          id: 'Board details',
          title: <FormattedMessage id="Board Details" />,
          type: 'item',
          name: 'BoardInfo',
          url: '/superadmin/board-info'
        },
        {
          icon: icons.icon4,
          id: 'subject details',
          title: <FormattedMessage id="Subject Details" />,
          type: 'item',
          name: 'SubjectInfo',
          url: '/superadmin/subject-info'
        },
        {
          icon: icons.icon2,
          id: 'question bank',
          title: <FormattedMessage id="Question Bank" />,
          type: 'item',
          name: 'Questions',
          url: '/superadmin/quiz/questions'
        },
        // {
        //   icon: icons.icon3,
        //   id: 'question group',
        //   title: <FormattedMessage id="Question Group" />,
        //   type: 'item',
        //   name: 'QuestionGroup',
        //   url: '/superadmin/question-group'
        // },
        {
          icon: icons.icon6,
          id: 'Assign Questions',
          title: <FormattedMessage id="Assign Questions" />,
          type: 'item',
          name: 'assignQuestions',
          url: '/superadmin/assign-questions'
        }
      ]
    }
  ]
};

export default superAdminQuestionBank;

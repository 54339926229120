import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  OutlinedInput,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { InputAdornment, TextField } from '@mui/material';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';
import { AttachCircle, CloseCircle, EmojiHappy, SearchNormal1, Send, Star, UserSearch } from 'iconsax-react';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import SimpleBar from 'components/third-party/SimpleBar';
import { dispatch, useSelector } from 'store';
import {
  removeUnreadCount,
  setChatLoading,
  setChatMessage,
  setChatSkip,
  setCurrentUser,
  setTotalChatCount,
  setUnreadChip,
  setUserChatId,
  setUserRoomId,
  setUserSearch,
  setUserSwitching
} from 'store/reducers/chat';
import UserList from 'sections/apps/chat/UserList';
import Loader2 from 'components/Loader2';
import ChatHistory from 'sections/apps/chat/ChatHistory';
import WaveAnimationDot from 'components/waveAnimationDot';
import MainCard from 'components/MainCard';
import EmojiPicker, { EmojiClickData, SkinTones } from 'emoji-picker-react';
import pdfIcon from 'assets/images/fileIcons/OF1HA11-ai (2).png';
import textIcon from 'assets/images/fileIcons/OF1HA11-ai (14).png';
import docIcon from 'assets/images/fileIcons/OF1HA11-ai (8).png';
import pptIcon from 'assets/images/fileIcons/OF1HA11-ai (1).png';
import otherIcon from 'assets/images/pdf-file_9680524.png';
import Snackbar from 'utils/Snackbar';
import { Chip } from '@mui/material';
import sapphireImage from 'assets/images/sapphire_logo.jpg';
import HideOnScroll from 'components/hideToScroll';
import Avatar from 'components/@extended/Avatar';
import { Image } from 'types/auth';

const debounce = (func: any, delay: number) => {
  let timeoutId: any;
  return (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const useChatDrawer = () => {
  const { user } = useAuth();
  const { getUsers, searchText, getRoomUsers } = useChat();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState('available');

  const handleClickRightMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRightMenu = () => {
    setAnchorEl(null);
  };

  const handleRightMenuItemClick = (userStatus: string) => () => {
    setStatus(userStatus);
    handleCloseRightMenu();
  };

  const fetchUsers = async (searchValue: string) => {
    searchValue != '' && (await getUsers(searchValue as string, user?.organizationId?._id as string));
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setUserSearch(event.target.value));
    user && fetchUsers(event.target.value);
  };

  return {
    anchorEl,
    status,
    searchText,
    handleClickRightMenu,
    handleCloseRightMenu,
    handleRightMenuItemClick,
    handleSearch
  };
};

const fileTypeToIcon = {
  'application/pdf': pdfIcon,
  'application/msword': otherIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docIcon,
  'text/plain': textIcon,
  'application/vnd.ms-powerpoint': pptIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': pptIcon
} as const;

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  type: string;
  isLoggedIn: boolean;
  unReadMessage?: string;
  isOnline?: boolean;
  userProfilePicId: Image;
  isAIChat?: boolean;
  roomId?: string;
}

interface AIUser {
  isAIChat: boolean;
  roomId: string;
}

interface RoomUser {
  users: User[];
  roomId: string;
  isAIChat: boolean;
}

const DrawerChat = ({ open, setOpen, openAiChat }: { open: boolean; setOpen: (val: boolean) => void; openAiChat: boolean }) => {
  const { searchText, handleSearch } = useChatDrawer();
  const mainWrapper = useRef<HTMLDivElement>(null);
  const { user, organization } = useAuth();
  const [isTyping, setIsTyping] = useState(false);
  const [anchorElEmoji, setAnchorElEmoji] = useState<Element | null>(null);
  const [data, setData] = useState<User[][]>([]);
  const currentRoomId = useSelector((state) => state.chat.roomId);
  const [imageData, setImageData] = useState<Array<any>>([]);
  const aiChatLoading = useSelector((state) => state.chat).AILoading;
  const AiChatId = useSelector((state) => state.chat.aiRoomId);
  const emojiOpen = Boolean(anchorElEmoji);
  const textInput = useRef<HTMLInputElement>(null);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const FocusTextInput = (chatText: any) => {
    dispatch(setChatMessage(chatText));
    textInput.current?.focus();
  };
  const AiUnreadCount = useSelector((state) => state.chat.aiUnreadCount);
  const {
    userSwitch,
    userChats,
    skip,
    userChatId,
    chatMessage,
    userRoomId,
    AiRoomId,
    currentUser,
    roomUsers,
    activeUser,
    createChat,
    createAIRoom,
    getRoomChats,
    sendIsTyping,
    updateChat
  } = useChat();
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  useEffect(() => {
    if (currentRoomId && skip !== 1) {
      // readAllMessages();
      getRoomChats(currentRoomId);
    } else {
      if (skip !== 1) {
        dispatch(setTotalChatCount(userChats.length - 1));
        dispatch(setChatLoading(false));
      }
    }
  }, [skip]);

  const handleStopTyping = () => {
    setIsTyping(false);
    if (user?._id && user?.type) sendIsTyping(user._id, currentUser?._id, user?.type, currentUser?.type, false);
  };

  const debouncedOnStopTyping = useCallback(
    debounce(handleStopTyping, 1000), // Adjust the delay as needed
    [currentUser?._id]
  );

  const excludePreviewField = (data: any) => {
    return data.map(({ preview, ...rest }: any) => rest);
  };

  useEffect(() => {
    if (openAiChat) {
      handleUserClick(AiRoomId, { roomId: AiRoomId, isAIChat: true }, AiUnreadCount);
    }
  }, [openAiChat]);

  const handleOnSend = async () => {
    const filesWithoutPreview = excludePreviewField(imageData);

    if (!currentUser) return;

    try {
      dispatch(setChatMessage(null));

      if (userChatId !== null && !currentUser?.isAIChat) {
        updateChat(userChatId as string, { text: chatMessage });
      } else {
        let createData: any = {};
        if (currentUser?.isAIChat) {
          createData.isAIChat = true;
        }
        if (chatMessage?.trim() !== '') {
          createData.text = chatMessage;
        }
        if (filesWithoutPreview && filesWithoutPreview.length > 0) {
          createData.files = filesWithoutPreview;
        }
        // console.log('createData', createData);
        // const createdChat = await createChat(userRoomId, createData);
        await createChat(userRoomId, createData);
        setImageData([]);

        // console.log('createdChat', createdChat);
      }
      dispatch(setUnreadChip(false));
      dispatch(setUserChatId(null));
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  type FileType = keyof typeof fileTypeToIcon;
  const getIconByFileType = (fileType: FileType): string | boolean => {
    return fileTypeToIcon[fileType] || false;
  };

  const handleFileChange = async (event: any) => {
    // const files = Array.from(event.target.files);

    const input = event.currentTarget;

    if (input.files && input.files.length > 0) {
      const files = Array.from(input.files);

      const totalSize: any = files.reduce((acc, file: any) => acc + file.size, 0);

      const MAX_SIZE = 10 * 1024 * 1024;
      if (totalSize > MAX_SIZE) {
        Snackbar('Total file size exceeds 10 MB. Please upload smaller files.', 'error');
        return;
      }

      const readFile = (file: any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const arrayBuffer = reader.result;
            if (arrayBuffer instanceof ArrayBuffer) {
              const buffer = Buffer.from(new Uint8Array(arrayBuffer));
              const base64String = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
              const fileData = {
                fileName: file.name,
                mimetype: file.type,
                data: buffer,
                preview: `data:${file.type};base64,${base64String}` // For image preview
              };
              resolve(fileData);
            } else {
              reject(new Error('Error reading file: result is not an ArrayBuffer'));
            }
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
      };

      try {
        const results = await Promise.all(files.map(readFile));
        setImageData(results);
      } catch (error) {
        console.error('Error reading files:', error);
      }
    }
  };

  const typing = useSelector((state) => state.chat.isTyping);

  const removeImage = (index: any) => {
    setImageData(imageData.filter((_, i) => i !== index));
  };

  const emojiId = emojiOpen ? 'simple-popper' : undefined;

  const handleOnEmojiButtonClick = (event: MouseEvent<HTMLButtonElement> | undefined) => {
    setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget ?? null);
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    const chatString = `${chatMessage || ''}${emojiObject.emoji}`;
    dispatch(setChatMessage(chatString));
  };

  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById('contained-button-file1');
    if (fileInput) {
      fileInput.click();
    }
  };

  const avatarImage = (require as any).context('assets/images/users', true);

  useEffect(() => {
    const filteredUsers =
      roomUsers?.length > 0 &&
      roomUsers?.slice(0, 5).map((roomUser: RoomUser) => {
        if (roomUser?.isAIChat) {
          return [{ roomId: roomUser?.roomId, isAIChat: true }];
        }
        return roomUser?.users?.filter((roomUser) => roomUser._id !== user?._id);
      });
    // const finalData =
    //   filteredUsers?.length > 0 ? filteredUsers.filter((val: any) => !(val?.[0]?.isAIChat && !organization?.GeminiApi)) : [];
    setData(filteredUsers);
  }, [roomUsers, user?._id, activeUser]);

  const handleUserClick = async (roomId: string, user: User | AIUser, count?: number) => {
    dispatch(setChatSkip(1));
    dispatch(setUserChatId(null));
    dispatch(setChatMessage(''));
    dispatch(setUserSwitching(true));
    await getRoomChats(roomId, 1);
    if (count && count > 0) {
      dispatch(removeUnreadCount(roomId));
    }
    dispatch(setUnreadChip(true));
    dispatch(setUserRoomId(roomId));
    dispatch(setCurrentUser(user));
    (matchDownLG || location.pathname !== '/learner/chat') && handleDrawerOpen();
  };
  return (
    <div>
      {open || openAiChat ? (
        <Stack ref={mainWrapper} sx={{ pt: '0px !important' }}>
          <SimpleBar
            sx={{
              overflowX: 'hidden',
              height: 'calc(100vh - 50px)',
              minHeight: 420,
              maxWidth: '320px'
            }}
          >
            <HideOnScroll target={mainWrapper}>
              <Box
                sx={{
                  position: 'fixed',
                  bgcolor: theme.palette.background.paper,
                  pr: 2,
                  py: 0.5,
                  // pb: 2,
                  zIndex: 1200,
                  width: '100%',
                  // pt: '16px !important',
                  borderBottom: `1px solid ${theme.palette.divider}`
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {currentUser && (
                        <Avatar
                          alt={currentUser?.isAIChat ? 'Sapphire Bot' : currentUser.firstName || 'User Name'}
                          src={
                            currentUser?.isAIChat
                              ? sapphireImage
                              : `data:${currentUser?.userProfilePicId?.mimetype};base64,${currentUser?.userProfilePicId?.file}` ||
                                avatarImage
                          }
                          size="md"
                          sx={{
                            // width: 47,
                            // height: '7vh',
                            border: '1px solid',
                            borderColor: theme.palette.primary.main,
                            p: 2,
                            bgcolor: 'transparent',
                            objectFit: 'contain',
                            '& .MuiAvatar-img': {
                              objectFit: 'contain',
                              height: '7vh',
                              width: '88px',
                              borderRadius: '50%'
                            }
                          }}
                        />
                      )}
                      <Stack>
                        <Typography variant="subtitle1">
                          {currentUser?.isAIChat ? 'Sapphire Bot' : currentUser.firstName + ' ' + currentUser.lastName || 'User Name'}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <Chip
                            variant="light"
                            label={currentUser?.isAIChat ? 'Bot' : currentUser.type || 'User Role'}
                            color={currentUser.type === 'admin' ? 'primary' : currentUser.type === 'faculty' ? 'warning' : 'info'}
                            sx={{ mt: 0.5, textTransform: 'capitalize' }}
                            size="small"
                          />
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </HideOnScroll>
            <Box sx={{ pl: 1, pr: 3, minHeight: 470 }}>
              {userSwitch && (
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height={'100%'} width={'100%'}>
                  <Loader2 />
                </Stack>
              )}

              {!userSwitch && userChats?.length > 0 ? (
                <ChatHistory textInputRef={FocusTextInput} innerChat={true} />
              ) : (
                !userSwitch && <Typography>No chat history available</Typography>
              )}
            </Box>
            <Box sx={{ mt: 0, px: 1, bgcolor: theme.palette.background.paper, borderTop: `1px solid ${theme.palette.divider}` }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {imageData?.length > 0 &&
                  imageData.map((imagePreview, index) => (
                    <Box
                      key={index}
                      sx={{
                        my: 0.5,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Box sx={{ position: 'relative', mr: 2 }}>
                        <Avatar
                          variant="square"
                          src={getIconByFileType(imagePreview?.mimetype) || imagePreview.preview}
                          alt="Preview"
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '4px'
                          }}
                        />
                        <IconButton sx={{ position: 'absolute', top: -20, right: -20 }} onClick={() => removeImage(index)}>
                          <CloseCircle size="10" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Box>
              {aiChatLoading && userRoomId === AiRoomId && <WaveAnimationDot />}
              <TextField
                inputRef={textInput}
                fullWidth
                multiline
                rows={2}
                placeholder="Your Message..."
                value={chatMessage ? chatMessage : ''}
                disabled={aiChatLoading && userRoomId === AiRoomId}
                onChange={(e) => {
                  if (user?._id && user?.type) {
                    if (!isTyping) {
                      if (!currentUser?.isAIChat) {
                        sendIsTyping(user._id, currentUser?._id, user?.type, currentUser?.type, true);
                      }
                    }
                    setIsTyping(true);
                  }
                  dispatch(setChatMessage(e.target.value));
                  if (!currentUser?.isAIChat) {
                    debouncedOnStopTyping();
                  }
                }}
                onKeyDown={(event) => {
                  // Handle Shift + Enter to insert a new line
                  const key = event.key;
                  if (event.shiftKey && key === 'Enter') {
                    event.preventDefault();
                    dispatch(setChatMessage(chatMessage + '\n'));

                    return;
                  }

                  // Handle Enter key to send message
                  if (!event.shiftKey && key === 'Enter') {
                    event.preventDefault(); // Prevent default behavior
                    if (chatMessage?.trim()?.length > 0) {
                      handleOnSend();
                    }
                    return;
                  }
                }}
                variant="standard"
                id="chatTextInput"
                sx={{
                  pr: 2,
                  '& .MuiInput-root:before': { borderBottomColor: theme.palette.divider }
                }}
              />
              <Typography variant="subtitle2">{typing?.[currentUser?._id] ? `${currentUser?.firstName} is typing` : ''}</Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" sx={{ py: 2, ml: -1 }}>
                  <IconButton
                    aria-describedby={emojiId}
                    onClick={handleOnEmojiButtonClick}
                    sx={{ opacity: 0.5 }}
                    size="medium"
                    color="secondary"
                  >
                    <EmojiHappy />
                  </IconButton>
                  <Popper
                    id={emojiId}
                    open={emojiOpen}
                    anchorEl={anchorElEmoji}
                    disablePortal
                    style={{ zIndex: 1200 }}
                    placement="top"
                    popperOptions={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            // offset: [-20, 125]
                            offset: [0, -10]
                          }
                        }
                      ]
                    }}
                  >
                    <ClickAwayListener onClickAway={handleCloseEmoji}>
                      <MainCard elevation={8} content={false}>
                        <EmojiPicker onEmojiClick={onEmojiClick} defaultSkinTone={SkinTones?.DARK} autoFocusSearch={false} />
                      </MainCard>
                    </ClickAwayListener>
                  </Popper>
                  {/* <Button sx={{ opacity: 0.5 }} size="medium" color="secondary" onClick={handleClick}></Button> */}
                  {currentUser?.isAIChat ? (
                    <div>
                      <input
                        accept=".gif, .jpeg, .jpg, .png,"
                        max-size="10485760"
                        id="contained-button-file1"
                        name="interiorPhotos"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      <IconButton onClick={handleButtonClick} aria-label="upload files">
                        <AttachCircle />
                      </IconButton>
                    </div>
                  ) : (
                    <div>
                      <input
                        accept=".gif, .jpeg, .jpg, .png, .pdf, .doc, .docx, .txt"
                        max-size="10485760"
                        id="contained-button-file1"
                        name="interiorPhotos"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      <IconButton onClick={handleButtonClick} aria-label="upload files">
                        <AttachCircle />
                      </IconButton>
                    </div>
                  )}
                </Stack>
                <IconButton
                  color="primary"
                  disabled={(chatMessage?.trim()?.length === 0 && imageData?.length === 0) || (aiChatLoading && userRoomId === AiRoomId)}
                  onClick={() => {
                    if (chatMessage?.trim()?.length > 0 || imageData?.length > 0) handleOnSend();
                  }}
                  size="large"
                  sx={{ mr: 1.5 }}
                >
                  <Send />
                </IconButton>
              </Stack>
            </Box>
          </SimpleBar>
        </Stack>
      ) : (
        <Stack my={1}>
          <Stack mx={1} direction={'row'} alignItems={'center'}>
            <OutlinedInput
              fullWidth
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              sx={{
                '& .MuiOutlinedInput-input': {
                  mx: 1
                }

                // '& .MuiOutlinedInput-input': { p: '10.5px 0px 12px'}
              }}
              endAdornment={
                <InputAdornment position="end">
                  <UserSearch size={22} variant="TwoTone" />
                </InputAdornment>
              }
            />
            {user?.type === 'student' && organization?.GeminiApi && (
              <Tooltip title={'chat with ai'}>
                <IconButton
                  sx={{
                    backgroundColor: 'transparent',
                    ml: 1,
                    transition: 'transform 0.3s ease', // Smooth transition
                    '&:hover': {
                      transform: 'scale(1.2)' // Grow effect on hover
                    }
                  }}
                  onClick={() => {
                    if (!AiRoomId) createAIRoom([user?._id as string]);
                    else handleUserClick(AiRoomId, { roomId: AiRoomId, isAIChat: true }, AiUnreadCount);
                  }}
                >
                  <Star color={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <style>
            {`  ::-webkit-scrollbar {
    width: 1px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  }
    #chatScroll{
      scrollbar-width:none;
    }
`}
          </style>
          <Stack direction={'row'} my={1} overflow={'auto'} id="chatScroll">
            {data?.length > 0 &&
              data.map((users, index) => (
                <Box>
                  {users?.map((val, index) => (
                    <Stack
                      sx={{ cursor: 'pointer', mx: 1 }}
                      onClick={() => {
                        const roomUser = roomUsers?.find((roomUser: any) => roomUser.users.includes(users[0]));
                        if (roomUser || val?.isAIChat) {
                          if (val?.isAIChat) {
                            handleUserClick(AiChatId, { roomId: AiChatId, isAIChat: true }, roomUsers[index]?.unreadChatCount);
                          } else {
                            handleUserClick(roomUser.roomId, users[0], roomUsers[index]?.unreadChatCount);
                          }
                        }
                      }}
                      minWidth={'64px'}
                      direction={'row'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Avatar
                        alt={val?.isAIChat ? 'Sapphire Bot' : val.firstName || 'User Name'}
                        size="md"
                        src={
                          val?.isAIChat
                            ? sapphireImage
                            : `data:${val?.userProfilePicId?.mimetype};base64,${val?.userProfilePicId?.file}` || avatarImage
                        }
                        type="outlined"
                      />
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        variant="subtitle1"
                      >
                        {val?.isAIChat ? 'Sapphire Bot' : val.firstName}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              ))}
          </Stack>
          <SimpleBar
            sx={{
              overflowX: 'hidden',
              height: matchDownLG ? 'calc(100vh - 120px)' : 'calc(100vh - 428px)',
              minHeight: matchDownLG ? 0 : '79vh'
            }}
          >
            <Box sx={{ py: 3, pt: 0 }}>
              <UserList closeDrawer={handleDrawerOpen} />
            </Box>
          </SimpleBar>
        </Stack>
      )}
    </div>
  );
};

export default DrawerChat;

import { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';

const HeaderOption = (props: any) => {
  const { user }: any = useAuth();
  const location = useLocation();
  const permission = user?.userTypeId?.permission;
  const [value, setValue] = useState(location.pathname);
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down(1440));
  const downXXL = useMediaQuery((theme: Theme) => theme.breakpoints.down(1690));
  const downXL = useMediaQuery((theme: Theme) => theme.breakpoints.down(1550));
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down(526));
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down(1266));
  // const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('1266'));

  // const [selectedTab, setSelectedTab] = useState(0);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setSelectedTab(newValue);
  // };

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%'
        // overflowX: 'auto',
        // whiteSpace: 'nowrap'
      }}
      className="navbar-control"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        onClick={props.handleClose}
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          sx: {
            backgroundColor: '#FFFFFF',
            height: 4,
            borderRadius: '20px 20px 0px 0px',
            position: 'absolute'
          }
        }}
      >
        {/* <Tab
          label="Dashboard"
          value="/learner"
          component={Link}
          to="/learner"
          onClick={props.handleClose}
          style={{
          fontFamily: 'poppins',
            color: value === '/learner' ? '#FFFFFF' : '#FFFFFFB2',
            padding: '0px 8px',
              marginBottom: '10px',
            fontSize: '15px',
            fontWeight: value == '/learner' ? '500' : '400'
          }}
        /> */}
        {permission?.UserCourses?.View && (
          <Tab
            label="My Courses"
            value="/learner/mylearning"
            component={Link}
            to="/learner/mylearning"
            onClick={props.handleClose}
            style={{
              fontFamily: 'poppins',
              color: value === '/learner/mylearning' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              fontSize: '15px',
              marginBottom: '10px',
              fontWeight: value == '/learner/mylearning' ? '500' : '400'
            }}
          />
        )}
        {permission?.Quiz?.View && (
          <Tab
            label="Quiz"
            value="/learner/quiz"
            component={Link}
            to="/learner/quiz"
            onClick={props.handleClose}
            style={{
              fontFamily: 'poppins',
              color: value === '/learner/quiz' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              marginBottom: '10px',
              fontSize: '15px',
              fontWeight: value == '/learner/quiz' ? '500' : '400'
            }}
          />
        )}
        {permission?.Quiz?.Contest && !downMD && (
          <Tab
            label="Contest"
            value="/learner/contest"
            component={Link}
            to="/learner/contest"
            onClick={props.handleClose}
            style={{
              fontFamily: 'poppins',
              color: value === '/learner/contest' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              marginBottom: '10px',
              fontSize: '15px',
              fontWeight: value == '/learner/contest' ? '500' : '400'
            }}
          />
        )}
        {permission?.Meeting?.View && !downLG && (
          <Tab
            label="Meeting"
            value="/learner/event"
            component={Link}
            to="/learner/event"
            onClick={props.handleClose}
            style={{
              fontFamily: 'poppins',
              color: value === '/learner/event' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              marginBottom: '10px',
              fontSize: '15px',
              fontWeight: value == '/learner/event' ? '500' : '400'
            }}
          />
        )}
        {permission?.UserCourses?.View && !downXL && (
          <Tab
            label="Recordings"
            value="/learner/recordings"
            component={Link}
            to="/learner/recordings"
            onClick={props.handleClose}
            style={{
              fontFamily: 'poppins',
              color: value === '/learner/recordings' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              fontSize: '15px',
              marginBottom: '10px',
              fontWeight: value == '/learner/recordings' ? '500' : '400'
            }}
          />
        )}
        {/* {permission?.Assignment?.View && (
          <Tab
            label="Assignments"
            value="/learner/assignments"
            component={Link}
            to="/learner/assignments"
            onClick={props.handleClose}
            style={{
            fontFamily: 'poppins',
              color: value === '/learner/assignments' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              marginBottom: '10px',
              fontSize: '15px',
              fontWeight: value == '/learner/assignments' ? '500' : '400'
            }}
          />
        )} */}
        {permission?.Blogs?.View && !downXXL && (
          <Tab
            label="Blogs"
            value="/learner/blog"
            component={Link}
            to="/learner/blog"
            onClick={props.handleClose}
            style={{
              fontFamily: 'poppins',
              color: value === '/learner/blog' ? '#FFFFFF' : '#FFFFFFB2',
              padding: '0px 8px',
              marginBottom: '10px',
              fontSize: '15px',
              fontWeight: value == '/learner/blog' ? '500' : '400'
            }}
          />
        )}
        {/* Add more tabs as needed */}
      </Tabs>
    </Box>
  );
};

export default HeaderOption;

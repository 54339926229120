import { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Box, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const HeaderOption = (props: any) => {
  const { user }: any = useAuth();
  const location = useLocation();
  // const anchorRef = useRef<any>(null);
  const permission = user?.userTypeId?.permission;
  const [value, setValue] = useState(location.pathname);
  const downMD1220 = useMediaQuery((theme: Theme) => theme.breakpoints.down(1220));
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down(1266));
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down(1440));
  const downXXL = useMediaQuery((theme: Theme) => theme.breakpoints.down(1690));
  const downXL = useMediaQuery((theme: Theme) => theme.breakpoints.down(1550));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(1266));
  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: isSmallScreen ? 'fit-content' : '100%',
        overflow: 'auto',
        maxHeight: '80vh',
        scrollbarWidth: 'none'
      }}
      className="navbar-control"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        onClick={props.handleClose}
        textColor="inherit"
        variant={'scrollable'}
        orientation={'vertical'}
        // indicatorColor="#063B9A"
        TabIndicatorProps={{
          sx: { backgroundColor: '#063B9A', height: { lg: 4, md: 4, sm: 30 }, overflow: 'scroll' }
        }}
      >
        <Tab
          label="Dashboard"
          value="/learner"
          component={Link}
          to="/learner"
          onClick={props.handleClose}
          style={{
            color: value === '/learner' ? '#063B9A' : '#5B6B79',
            textDecoration: 'none',
            padding: '0px 8px',
            marginBottom: '-8px',
            fontSize: '16px',
            fontWeight: '500'
          }}
        />
        {permission?.UserCourses?.View && downMD1220 && (
          <Tab
            label="My Learning"
            value="/learner/mylearning"
            component={Link}
            to="/learner/mylearning"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/mylearning' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Meeting?.View && downLG && (
          <Tab
            label="Meeting"
            value="/learner/event"
            component={Link}
            to="/learner/event"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/event' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Quiz?.Contest && downMD && (
          <Tab
            label="Contest"
            value="/learner/contest"
            component={Link}
            to="/learner/contest"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/contest' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Quiz?.View && downMD1220 && (
          <Tab
            label="Quiz"
            value="/learner/quiz"
            component={Link}
            to="/learner/quiz"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/quiz' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Skills?.View && (
          <Tab
            label="Skills"
            value="/learner/skills"
            component={Link}
            to="/learner/skills"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/skills' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.TimeTable?.View && (
          <Tab
            label="Time-Table"
            value="/learner/timetable"
            component={Link}
            to="/learner/timetable"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/timetable' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.TimeTable?.View && (
          <Tab
            label="Results"
            value="/learner/result"
            component={Link}
            to="/learner/result"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/result' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {(permission?.Events?.View || permission?.Meeting?.View) && (
          <Tab
            label="Events"
            value="/learner/event"
            component={Link}
            to="/learner/event"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/event' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Discussions?.View && (
          <Tab
            label="Discussions"
            value="/learner/discussion"
            component={Link}
            to="/learner/discussion"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/discussion' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Assignment?.View && (
          <Tab
            label="Assignments"
            value="/learner/assignments"
            component={Link}
            to="/learner/assignments"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/assignments' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              marginBottom: permission?.Tickets?.View ? '-8px' : '0px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.UserCourses?.View && downXL && (
          <Tab
            label="Recordings"
            value="/learner/recordings"
            component={Link}
            to="/learner/recordings"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/recordings' ? '#063B9A' : '#5B6B79',
              padding: '0px 8px',
              fontSize: '16px',
              marginBottom: '-10px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Tickets?.View && (
          <Tab
            label="Tickets"
            value="/learner/ticket"
            component={Link}
            to="/learner/ticket"
            style={{
              color: value === '/learner/ticket' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              // marginBottom: '-8px',
              marginBottom: permission?.UserCourses?.Purchase ? '-8px' : '0px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}

        {permission?.UserCourses?.Purchase && (
          <Tab
            label="Cart"
            value="/learner/checkout"
            component={Link}
            to="/learner/checkout"
            style={{
              color: value === '/learner/checkout' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              // marginBottom: '-8px',
              marginBottom: permission?.UserCourses?.Purchase ? '-8px' : '0px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.UserCourses?.Purchase && (
          <Tab
            label="Wishlist"
            value="/learner/wishlist"
            component={Link}
            to="/learner/wishlist"
            style={{
              color: value === '/learner/wishlist' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              // marginBottom: '-8px',
              marginBottom: permission?.Subscription?.View ? '-8px' : '0px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Subscription?.View && (
          <Tab
            label="Packages"
            value="/learner/packages"
            component={Link}
            to="/learner/packages"
            style={{
              color: value === '/learner/packages' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              // padding: '0px 8px',
              marginBottom: permission?.Timeline?.View ? '-8px' : '0px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {/* {permission?.Chats?.View && (
          <Tab
            label="Chats"
            value="/learner/chat"
            component={Link}
            to="/learner/chat"
            style={{
              color: value === '/learner/chat' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 20px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )} */}
        {permission?.Timeline?.View && (
          <Tab
            label="Timeline"
            value="/learner/timeline"
            component={Link}
            to="/learner/timeline"
            style={{
              color: value === '/learner/timeline' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              // marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Blogs?.View && downXXL && (
          <Tab
            label="Blogs"
            value="/learner/blog"
            component={Link}
            to="/learner/blog"
            style={{
              color: value === '/learner/blog' ? '#063B9A' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              // marginBottom: '-8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
      </Tabs>
    </Box>
  );
};

export default HeaderOption;

import { useMemo, useState } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Stack, Grid, OutlinedInput, Button, Container } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// project-imports
import Message from 'layout/MainLayout/Header/HeaderContent/Message';
import Profile from 'layout/MainLayout/Header/HeaderContent/Profile';
import Localization from 'layout/MainLayout/Header/HeaderContent/Localization';
import Notification from 'layout/MainLayout/Header/HeaderContent/Notification';
import MobileSection from 'layout/MainLayout/Header/HeaderContent/MobileSection';
// import MegaMenuSection from 'layout/MainLayout/Header/HeaderContent/MegaMenuSection';

import useConfig from 'hooks/useConfig';
import Logo from 'components/logo';
// type
// import { Logout } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import HeaderOption from './HeaderOption2';
import Cart from 'layout/MainLayout/Header/HeaderContent/cart';
import WishList from 'layout/MainLayout/Header/HeaderContent/wishList';
import { Magicpen, SearchNormal1 } from 'iconsax-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { setLernerSearch } from 'store/reducers/courseSlice';
import askAiLogo from '../../../../assets/images/homepage/ask-ai.svg';

// ==============================|| HEADER - CONTENT ||============================== //

const StudentHeaderContent = ({
  hideDrawer,
  isStudent,
  handleAiChat
}: {
  hideDrawer: boolean;
  isStudent: boolean;
  handleAiChat?: () => void;
}) => {
  const { i18n } = useConfig();
  const location = useLocation();
  const ifInQuiz = location.pathname === '/learner/quiz/test';
  const ifInExams = location.pathname === ' /learner/test';
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down(1220));
  const down842SM = useMediaQuery((theme: Theme) => theme.breakpoints.down(842));
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down(526));
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down(1024));
  const [hover, setHover] = useState(false);

  const { pathname } = location;
  const inCourse = pathname === '/learner/mylearning/course';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  const { user } = useAuth();
  const permission = user?.userTypeId?.permission;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [openAiChat, setOpenAiChat] = useState(false);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(setLernerSearch(searchText));
      if (location.pathname !== '/learner/course' && searchText !== '') {
        navigate('/learner/course', { state: { searchText: searchText } });
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ maxWidth: '1600px !important' }}>
      <Grid container direction="column">
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#063B9A',
              width: '100%',
              px: downSM ? '12px' : '26px',
              py: downLG ? 1.5 : 0,
              // py: inCourse ? 0.25 : downLG ? 1.5 : 0,
              pointerEvents: ifInQuiz || ifInExams ? 'none' : 'auto'
            }}
          >
            <Box className="logo-welcome">{hideDrawer && <Logo to={'/learner'} />}</Box>
            {/* {!downLG && megaMenu} */}

            {!downLG && (
              <Box sx={{ pt: 1.5 }}>
                <HeaderOption />
              </Box>
            )}
            {/* {!downSM && (
              <img
                onClick={handleAiChat}
                src={askAiLogo}
                width={downMD ? '92px' : '110px'}
                height="auto"
                style={{ objectFit: 'contain', maxHeight: '48px', cursor: 'pointer' }}
              />
            )} */}
            {!downSM && (
              <Button
                startIcon={<Magicpen />}
                style={{
                  fontFamily: 'poppins',
                  background: hover
                    ? 'linear-gradient(100.47deg, #D446E1 1.88%, #EA5353 96.17%)'
                    : 'linear-gradient(100.47deg, #EA5353 1.88%, #D446E1 96.17%)',
                  width: downMD ? '92px' : '112px',
                  height: '45px',
                  border: '1px solid #D1D1D1',
                  color: '#fff',
                  borderRadius: '12px',
                  fontWeight: 500,
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  boxShadow: hover ? '0px 4px 10px rgba(0, 0, 0, 0.15)' : 'none'
                }}
                onClick={handleAiChat}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {' '}
                Ask AI
              </Button>
            )}
            <Box sx={{ display: down842SM ? 'none' : 'block', pr: 2 }} className="search-box">
              <OutlinedInput
                startAdornment={<SearchNormal1 />}
                placeholder="Search Courses..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value === '') {
                    dispatch(setLernerSearch(e.target.value));
                  }
                }}
                onKeyPress={handleKeyPress}
                // onKeyPress={(event) => {
                //   if (event.key === 'Enter' && searchText.trim() !== '') {
                //     navigate('/learner/course', { state: searchText });
                //   }
                // }}
                sx={{
                  width: { sm: 'auto' },
                  minHeight: '46px',
                  ml: 2,
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  '& input::placeholder': {
                    color: '#9E9E9E'
                  }
                }}
              />
            </Box>
            <Stack display="flex" direction="row" alignItems="center" spacing={2} sx={{ ml: downSM ? 1 : 0 }}>
              {!downMD && user?.type !== 'superadmin' && permission?.Courses?.View && <WishList />}
              {/* {downLG && <div style={{ width: '100%', marginLeft: 1 }} />} */}
              <Notification />
              {/* {localization}

              {!downLG && user?.type !== 'superadmin' && permission?.Courses?.View && <Message />}
              {!downLG && permission?.UserCourses?.Purchase && user?.type !== 'superadmin' && <Cart />} */}

              <Box className="profile-control" sx={{ overflow: 'hidden', ml: downSM ? '8px !important' : 2, pr: downSM ? 0 : 1 }}>
                <Profile />
              </Box>
              <MobileSection isStudent={isStudent} />
            </Stack>
          </Box>
        </Grid>
        {/* <Grid item>
          <div style={{ display: 'flex', backgroundColor: '#FFF', flexDirection: 'column-reverse', alignItems: 'flex-end' }}>
            {!downLG && isStudent && <HeaderOption />}
          </div>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default StudentHeaderContent;


import { Outlet } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { LinearProgressProps } from '@mui/material/LinearProgress';


// ==============================|| LOADER - WRAPPER ||============================== //

export interface LoaderProps extends LinearProgressProps {}

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = ({ layout = 'blank' }: { layout?: string }) => {

  return (
    <>
      {layout === 'blank' && <Outlet />}
    </>
  );
};

export default CommonLayout;

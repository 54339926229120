import { Tooltip, IconButton } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { Heart } from 'iconsax-react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

const WishList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuth();
  return (
    <Tooltip title={<FormattedMessage id="Wishlist" />}>
      <IconButton
        sx={{
          width: user?.type === 'student' ? '45px' : '40px',
          height: user?.type === 'student' ? '45px' : '40px',
          border: user?.type === 'student' ? 'none' : 'none',
          borderRadius: user?.type === 'student' ? '1000px' : 'none',
          color: '#1D1D1F',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: user?.type === 'student' ? '#FFFFFF' : 'transparent'
          },
          p: 0.75
        }}
        onClick={() => {
          if (user?.type === 'student') {
            navigate('/learner/wishlist');
          } else {
            navigate('/course/wishlist');
          }
        }}
      >
        {/* <Badge color="#FF781D" style={{ '& .MuiBadge-badge': { top: 2, right: 4 } } as React.CSSProperties}> */}
          <Heart
            className="mainlogoicon"
            color={user?.type === 'student' ? '#063B9A' : undefined}
            variant={location.pathname === '/learner/wishlist' || location.pathname === '/course/wishlist' ? 'Bold' : 'Outline'}
          />
        {/* </Badge> */}
      </IconButton>
    </Tooltip>
  );
};

export default WishList;

import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, ClickAwayListener, Paper, Popper, Theme, Toolbar, useMediaQuery } from '@mui/material';

// project-imports
// import Search from './Search';
import Profile from './Profile';
import Localization from './Localization';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { HambergerMenu, MoreSquare } from 'iconsax-react';
import HeaderOption from 'layout/ContentLayout/Header/HeaderContent/HeaderOption';
import useAuth from 'hooks/useAuth';

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

const MobileSection = (isStudent: { isStudent?: boolean }) => {
  const theme = useTheme();
  const { user }: any = useAuth();
  const permision = user?.userTypeId?.permission;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down(526));
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: downSM ? '8px !important' : 2 }}>
        <IconButton
          aria-label="open more menu"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
          variant="light"
          size="large"
          sx={{
            width: user?.type === 'student' ? '45px' : '40px',
            height: user?.type === 'student' ? '45px' : '40px',
            border: user?.type === 'student' ? 'none' : 'none',
            borderRadius: user?.type === 'student' ? '1000px' : 'none',
            color: '#1D1D1F',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: user?.type === 'student' ? '#FFFFFF' : 'transparent'
            },
            p: 0.75
          }}
        >
          <HambergerMenu
            color={user?.type === 'student' ? '#1D1D1F' : undefined}
            size={28}
            variant={user?.type === 'student' ? undefined : 'Bulk'}
          />
        </IconButton>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ width: 'fit-content' }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1, width: 'fit-content' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  {/* <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: { lg: 'center', md: 'center', sm: 'baseline', xs: 'baseline' } }}> */}
                  {/* <Search /> */}
                  <Toolbar
                    sx={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      justifyContent: { sm: 'center', md: 'space-between' },
                      alignItems: 'flex-end'
                    }}
                  >
                    <Box
                      sx={{
                        p: 1.5,
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        width: 'fit-content',
                        bgcolor: 'background.paper',
                        border: '1px #13AC9E',
                        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                        borderRadius: '12px'
                      }}
                    >
                      {user?.type === 'student' && <HeaderOption handleClose={handleClose} />}
                      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' } }}>
                          {permision?.UserCourses?.Purchase && user?.type !== 'superadmin' && user?.type !== 'admin' && <Cart />}
                        </Box>
                        {!downLG && user?.type !== 'superadmin' && user?.type !== 'admin' && <WishList />}
                        <Box sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, mx: 1 }}>
                          <Localization />
                        </Box>
                        <Profile />
                      </Box> */}
                    </Box>
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;

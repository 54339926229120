// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Video, KeyboardOpen, PenAdd } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Video: Video,
  whiteBoardIcon:KeyboardOpen,
  draw: PenAdd
};

// ==============================|| MENU ITEMS - category ||============================== //

const Whiteboard: NavItemType = {
  id: 'group-whiteboard',
  type: 'group',
  icon: icons.whiteBoardIcon,
  name: 'Whiteboard',
  children: [
    {
      icon: icons.whiteBoardIcon,
      id: 'whiteboard',
      title: <FormattedMessage id="Whiteboard" />,
      type: 'collapse',
      children: [
        {
            icon: icons.draw,
            id: 'board',
            title: <FormattedMessage id="Board" />,
            type: 'item',
            name: 'Draw',
            url: '/whiteboard/board'
        },
        {
          icon: icons.Video,
          id: 'recordings',
          title: <FormattedMessage id="Recordings" />,
          type: 'item',
          name: 'Recordings',
          url: '/whiteboard/recordings'
        },
      ]
    }
  ]
};

export default Whiteboard;

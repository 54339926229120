import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// project-imports
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import useConfig from 'hooks/useConfig';

// assets
import { FormatCircle, LanguageSquare } from 'iconsax-react';

// types
import { I18n } from 'types/config';
import MainCard from 'components/MainCard';
import { FormattedMessage } from 'react-intl';
import Loader2 from 'components/Loader2';
import useAuth from 'hooks/useAuth';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const { i18n, onChangeLocalization, onChangeDirection, themeDirection } = useConfig();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (lang: I18n) => {
    // console.log('Language Change', lang);

    // if (lang === 'ar' && themeDirection === 'ltr') {
    //   onChangeDirection('rtl');
    // } else if (lang !== 'ar' && themeDirection === 'rtl') {
    //   onChangeDirection('ltr');
    // }
    onChangeLocalization(lang);
    setOpen(false);
  };

  // const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  // const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <Tooltip title={<FormattedMessage id="language" />}>
        <IconButton
          color="secondary"
          variant="light"
          aria-label="open localization"
          ref={anchorRef}
          aria-controls={open ? 'localization-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="large"
          sx={{
            width: user?.type === 'student' ? '32px' : '40px',
            height: user?.type === 'student' ? '32px' : '40px',
            border: user?.type === 'student' ? '1px solid white' : undefined,
            color: 'secondary.main',
            backgroundColor: {
              lg: 'transparent',
              md: 'transparent',
              sm: user?.type === 'student' ? '#13AC9E' : 'transparent',
              xs: user?.type === 'student' ? '#13AC9E' : 'transparent'
            },
            '&:hover': {
              backgroundColor: user?.type === 'student' ? '#0f8a7e' : '#transparent'
            },
            p: 0.75
          }}
        >
          <LanguageSquare
            color={user?.type === 'student' ? 'white' : undefined}
            className="mainlogoicon"
            variant={user?.type === 'student' ? undefined : 'Bulk'}
          />
        </IconButton>
      </Tooltip>
      <Popper
        placement={matchesXs ? 'bottom-start' : 'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 100000 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 0 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top-right' : 'top'} in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1, borderRadius: 1.5 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <List
                    component="nav"
                    sx={{
                      p: 1,
                      width: '100%',
                      minWidth: 200,
                      maxWidth: 290,
                      bgcolor: theme.palette.background.paper,
                      [theme.breakpoints.down('md')]: {
                        maxWidth: 250
                      }
                    }}
                  >
                    <ListItemButton selected={i18n === 'en'} onClick={() => handleListItemClick('en')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">English</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (UK)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton selected={i18n === 'fr'} onClick={() => handleListItemClick('fr')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">français</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (French)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton selected={i18n === 'ro'} onClick={() => handleListItemClick('ro')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">Română</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (Romanian)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton selected={i18n === 'zh'} onClick={() => handleListItemClick('zh')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">中国人</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (Chinese)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton selected={i18n === 'ar'} onClick={() => handleListItemClick('ar')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">عربي</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (Arabic)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton selected={i18n === 'hindi'} onClick={() => handleListItemClick('hindi')}>
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">हिंदी</Typography>
                            <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                              (Hindi)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};
export default Localization;

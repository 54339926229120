import { useState, MouseEvent } from 'react';

// material-ui
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';

// assets
import { Activity, Award, Cup, Edit2, Logout, Moon, Setting2, Setting4, TableDocument } from 'iconsax-react';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  setOpen?: () => void;
}

const ProfileTab = ({ handleLogout, setOpen }: Props) => {
  const { user }: any = useAuth();
  const permission = user?.userTypeId?.permission;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { mode, onChangeMode } = useConfig();
  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
    if (setOpen) {
      setOpen();
    }
  };
  const handleThemeModeToggle = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    onChangeMode(newMode);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/* {permission?.Users?.Update && (
        <Link to={user?.type === 'student' ? '/learner/profile' : '/profile'} style={{ textDecoration: 'none' }} state={{ rowData: user }}>
          <ListItemButton selected={selectedIndex === 4} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
            <ListItemIcon>
              <Edit2 variant="Bulk" size={18} />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="Update Profile" />} />
          </ListItemButton>
        </Link>
      )} */}

      {user?.type !== 'superadmin' && user?.type !== 'superAdmin' && user?.type !== 'support' && (
        <>
          {permission?.UserCourses?.Purchase && user?.type !== 'admin' && user?.type !== 'faculty' && (
            <Link to={user?.type === 'student' ? '/learner/order-history' : '/order-history'} style={{ textDecoration: 'none' }}>
              <ListItemButton selected={selectedIndex === 4} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>
                <ListItemIcon>
                  <TableDocument variant="Bulk" size={18} />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="Order History" />} />
              </ListItemButton>
            </Link>
          )}

          {user?.type === 'student' && (
            <Link to="/learner/my-activity" style={{ textDecoration: 'none' }}>
              <ListItemButton selected={selectedIndex === 4} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>
                <ListItemIcon>
                  <Activity variant="Bulk" size={18} />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="My Activity" />} />
              </ListItemButton>
            </Link>
          )}

          {permission?.Badges?.View && user?.type === 'student' && (
            <Link to="/learner/badges" style={{ textDecoration: 'none' }}>
              <ListItemButton selected={selectedIndex === 4} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>
                <ListItemIcon>
                  <Cup variant="Bulk" size={18} />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="Badges" />} />
              </ListItemButton>
            </Link>
          )}

          {permission?.Certificates?.View && user?.type !== 'admin' && user?.type !== 'faculty' && (
            <Link
              to={user?.type === 'student' ? '/learner/certificate/mycertificate' : '/certificate/mycertificate'}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton selected={selectedIndex === 5} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 5)}>
                <ListItemIcon>
                  <Award variant="Bulk" size={18} />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="Certificate" />} />
              </ListItemButton>
            </Link>
          )}

          {user?.type !== 'student' && (
            <Link
              to='/getStarted'
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton selected={selectedIndex === 6} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 6)}>
                <ListItemIcon>
                  <Setting4 variant="Bulk" size={18} />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="Getting Started" />} />
              </ListItemButton>
            </Link>
          )}

          {permission?.AccountSettings?.View && (
            <Link
              to={user?.type === 'student' ? '/learner/settings/accountSettings' : '/settings/accountSettings'}
              style={{ textDecoration: 'none' }}
            >
              <ListItemButton selected={selectedIndex === 8} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>
                <ListItemIcon>
                  <Setting2 variant="Bulk" size={18} />
                </ListItemIcon>
                <ListItemText primary={<FormattedMessage id="Settings" />} />
              </ListItemButton>
            </Link>
          )}
        </>
      )}

      {/* <Link to={'/learner/mylearning'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItemButton selected={selectedIndex === 6}>
          <ListItemIcon>
            <Heart variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="My Favorite Courses" />
        </ListItemButton>
      </Link> */}
      {/* {user?.type !== 'student' && (
        <ListItemButton selected={selectedIndex === 7}>
          <ListItemIcon>
            <Moon variant="Bulk" size={18} />
          </ListItemIcon>

          <ListItemText primary={<FormattedMessage id="Dark Mode" />} />
          <Switch checked={mode === 'dark'} onChange={handleThemeModeToggle} />
        </ListItemButton>
      )} */}
      <Divider />
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="Logout" />} />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    whiteboards: [],
    ViewWhiteboards: [],
    search: '',
    pageIndex: 1,
    pageSize: 10,
    expandSectionId: '',
    currentContentData: {},
    currentWatchingLecture: {} // This state is use for storing user(learner) watching whiteboard recording lecture/video
};

const whiteboardSlice = createSlice({
    name: 'whiteboards',
    initialState,
    reducers: {
        getWhiteboards(state, action) {
            return {
                ...state,
                whiteboards: action.payload
            };
        },
        setWhiteboardPageIndex(state, action) {
            state.pageIndex = action.payload;
        },
        setWhiteboardPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setWhiteboardSearch(state, action) {
            state.search = action.payload;
        },
        setWhiteboardStatus(state, action) {
            const whiteboardData = state.whiteboards.map((whiteboard: any) => {
                if (whiteboard._id === action.payload) return { ...whiteboard, published: !whiteboard.published };
                return whiteboard;
            });
            return {
                ...state,
                whiteboards: whiteboardData,
              };
        },
        delWhiteboards(state, action) {
            return {
                ...state,
                whiteboards: state.whiteboards.filter((item: { _id: any }) => item._id !== action.payload)
            };
        },
        setViewWhiteboards(state, action) {
            return {
                ...state,
                ViewWhiteboards: action.payload
            };
        },
        moveToNextOrPreviousSection(state, action) {
            return {
                ...state,
                expandSectionId: action.payload
            };
        },
        moveToNextOrPreviousContent(state, action) {
            return {
                ...state,
                currentContentData: action.payload
            };
        },
        setCurrentWatchingLecture(state, action) {
            return {
                ...state,
                currentWatchingLecture: action.payload
            };
        },
        resetStateWhiteboards: (state) => {
            return initialState;
        }
    }
});

export const { 
    getWhiteboards, 
    delWhiteboards, 
    setViewWhiteboards, 
    setWhiteboardSearch, 
    setWhiteboardStatus,
    resetStateWhiteboards, 
    setWhiteboardPageSize, 
    setWhiteboardPageIndex, 
    setCurrentWatchingLecture,
    moveToNextOrPreviousSection,
    moveToNextOrPreviousContent 
} = whiteboardSlice.actions;

export default whiteboardSlice.reducer;

import React, { type ChangeEvent, createContext, useContext, useEffect, useRef, useState } from 'react';
import { ReactSketchCanvas, type ReactSketchCanvasRef } from 'react-sketch-canvas';
import { Tool } from 'types/canvas';
import type { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
interface CanvasContextType {
  reactSketchCanvasRef: React.RefObject<ReactSketchCanvasRef>;
  strokeWidth: number;
  eraserWidth: number;
  strokeColor: string;
  setStrokeColor: (color: string) => void;

  // custom method
  canvasDocRef: React.RefObject<HTMLCanvasElement>;
  activeTool: Tool;
  strokeWidthSliderVisible: boolean;
  fileType: string | null;
  setFileType: (type: string | null) => void;
  fileUrl: string | null | Blob;
  setFileUrl: (url: string | null | Blob) => void;
  file: PDFDocumentProxy | HTMLImageElement | null;
  setFile: (file: PDFDocumentProxy | HTMLImageElement | null) => void;
  pageNum: number;
  totalPages: number;
  setTotalPages: (page: number) => void;
  onToolChange: (tool: Tool) => void;
  setStrokeWidthSliderVisible: (bool: boolean) => void;
  handleEraserClick: () => void;
  handlePenClick: () => void;
  handleStrokeWidthChange: (newStrokeWidth: number) => void;
  handleUndoClick: () => void;
  handleRedoClick: () => void;
  handleClearClick: () => void;
  handleResetClick: () => void;
  handleCanvasOnChange: () => void;
  updateCanvasBackground: () => void;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
}

const CanvasContext = createContext<CanvasContextType | null>(null);

type initialToolsValueTypes = {
  tool: Tool;
  color: string;
  strokeWidth: number;
};
const initialToolsValue: initialToolsValueTypes = {
  tool: 'pen',
  color: '#000000',
  strokeWidth: 5
};

export function CanvasProvider({ children }: { children: React.ReactNode }) {
  // ReactSketchCanvas method
  const reactSketchCanvasRef = useRef<ReactSketchCanvasRef>(null);
  const [eraseMode, setEraseMode] = useState<boolean>(false);
  const [strokeWidth, setStrokeWidth] = useState<number>(initialToolsValue.strokeWidth);
  const [eraserWidth, setEraserWidth] = useState<number>(30);
  const [strokeColor, setStrokeColor] = useState<string>(initialToolsValue.color);
  // const [canvasColor, setCanvasColor] = useState('#ffffff');

  // custom method
  const canvasDocRef = useRef<HTMLCanvasElement>(null);
  const [activeTool, setActiveTool] = useState<Tool>(initialToolsValue.tool);
  const [strokeWidthSliderVisible, setStrokeWidthSliderVisible] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null | Blob>(null);
  const [file, setFile] = useState<PDFDocumentProxy | HTMLImageElement | null>(null);
  const [pageNum, setPageNum] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    setPageNum(1);
  }, [fileUrl]);

  useEffect(() => {
    handleClearClick();
    updateCanvasBackground();
  }, [file, pageNum]);

  const updateCanvasBackground = async () => {
    const canvasDoc = canvasDocRef.current;
    if (!canvasDoc) return;

    const contextDoc = canvasDoc.getContext('2d');
    if (!contextDoc) return;
    if (file && canvasDoc && fileType?.includes('pdf')) {
      console.log('In pdf');

      const page: PDFPageProxy = await file.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1 });

      canvasDoc.width = viewport.width;
      canvasDoc.height = viewport.height;

      const renderContext = {
        canvasContext: contextDoc,
        viewport
      };

      // Render the page
      await page.render(renderContext).promise;
    } else if (file && canvasDoc && fileType?.includes('image')) {
      console.log('In image');

      if (file instanceof HTMLImageElement) {
        // Handle images specifically
        canvasDoc.width = file.width; // Match canvas size to image
        canvasDoc.height = file.height;

        // Draw the image on the canvas
        contextDoc.drawImage(file, 0, 0);
      } else {
        console.error('Error: File is not an image.');
      }
    }
  };

  const onToolChange = (tool: Tool) => {
    setActiveTool(tool);

    if (tool == 'pen') {
      handlePenClick();
    }

    if (tool == 'eraser') {
      handleEraserClick();
    }
  };

  const handleEraserClick = () => {
    setEraseMode(true);
    reactSketchCanvasRef.current?.eraseMode(true);
  };

  const handlePenClick = () => {
    setEraseMode(false);
    reactSketchCanvasRef.current?.eraseMode(false);
  };

  const handleStrokeWidthChange = (newStrokeWidth: number) => {
    setStrokeWidth(newStrokeWidth);
  };

  const handleEraserWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEraserWidth(+event.target.value);
  };

  const handleUndoClick = () => {
    reactSketchCanvasRef.current?.undo();
  };

  const handleRedoClick = () => {
    reactSketchCanvasRef.current?.redo();
  };

  const handleClearClick = () => {
    reactSketchCanvasRef.current?.clearCanvas();
  };

  const handleResetClick = () => {
    reactSketchCanvasRef.current?.resetCanvas();
    setStrokeWidth(initialToolsValue.strokeWidth);
  };

  const handleCanvasOnChange = () => {
    // console.log('handleCanvasOnChange called ....');
    if (strokeWidthSliderVisible) {
      setStrokeWidthSliderVisible(false);
    }
  };

  const goToNextPage = () => {
    if (pageNum < totalPages) {
      setPageNum(pageNum + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageNum > 1) {
      setPageNum(pageNum - 1);
    }
  };

  return (
    <CanvasContext.Provider
      value={{
        reactSketchCanvasRef,
        strokeWidth,
        eraserWidth,
        strokeColor,
        setStrokeColor,

        // custom method
        canvasDocRef,
        activeTool,
        strokeWidthSliderVisible,
        fileType,
        setFileType,
        fileUrl,
        setFileUrl,
        file,
        setFile,
        pageNum,
        totalPages,
        setTotalPages,
        onToolChange,
        setStrokeWidthSliderVisible,
        handleEraserClick,
        handlePenClick,
        handleStrokeWidthChange,
        handleUndoClick,
        handleRedoClick,
        handleClearClick,
        handleResetClick,
        handleCanvasOnChange,
        updateCanvasBackground,
        goToPreviousPage,
        goToNextPage
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
}

export function useCanvas() {
  const context = useContext(CanvasContext);
  if (!context) {
    throw new Error('useCanvas must be used within a CanvasProvider');
  }
  return context;
}

import { Badge, IconButton, Tooltip, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { ShoppingCart } from 'iconsax-react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

const Cart = () => {
  const navigate = useNavigate();
  const { user, organization } = useAuth();
  const consent = organization?.basicsettings?.identity?.sellingConsent;
  const { cart } = useSelector((state: any) => state.cart);
  const location = useLocation();
  const theme = useTheme();
  return (
    <>
      {consent && (
        <Tooltip title={<FormattedMessage id="Cart" />}>
          <IconButton
            size="large"
            sx={{
              width: user?.type === 'student' ? '32px' : '40px',
              height: user?.type === 'student' ? '32px' : '40px',
              border: user?.type === 'student'
                ? {
                  lg: '1px solid white',
                  md: '1px solid white',
                  sm: '1px solid #063B9A',
                  xs: '1px solid #063B9A'
                }
                : undefined,
              color: 'secondary.main',
              backgroundColor: {
                lg: 'transparent',
                md: 'transparent',
                sm: user?.type === 'student' ? '#063B9A' : 'transparent',
                xs: user?.type === 'student' ? '#063B9A' : 'transparent'
              },
              '&:hover': {
                backgroundColor: user?.type === 'student' ? '#0f8a7e' : 'transparent'
              },
              p: 0.75
            }}
            onClick={() => navigate(consent && user?.type === 'student' ? '/learner/checkout' : 'checkout')}
          >
            {cart ? (
              <Badge
                badgeContent={
                  cart?.cartItems?.groupItems?.length || cart?.cartItems?.courseItems?.length || cart?.cartItems?.questionBankItems?.length
                    ? cart?.cartItems?.groupItems?.length + cart?.cartItems?.courseItems?.length + cart?.cartItems?.questionBankItems?.length
                    : 0
                }
                color="secondary"
                sx={{ '& .MuiBadge-badge': { top: 2, right: 4 } }}
              >
                <ShoppingCart
                  color={user?.type === 'student' ? 'white' : undefined}
                  className="mainlogoicon"
                  variant={location.pathname === '/learner/checkout' || location.pathname === '/checkout' ? 'Bulk' : 'Outline'}
                />
              </Badge>
            ) : (
              // <ShoppingCart
              //   variant={location.pathname === '/learner/checkout' ? 'Bold' : 'Outline'}
              //   color={location.pathname === '/learner/checkout' ? theme.palette.primary.light : theme.palette.secondary.main}
              // />
              <ShoppingCart
                color={user?.type === 'student' ? 'white' : undefined}
                className="mainlogoicon"
                variant={location.pathname === '/learner/checkout' || location.pathname === '/checkout' ? 'Bulk' : 'Outline'}
              />
            )}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
export default Cart;

import { useState } from 'react';
import { Box, Chip, Collapse, Divider, Grid, Stack, Typography, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import { Add, ArrowDown2, DirectInbox, ArrowUp2 } from 'iconsax-react';
import useChat from 'hooks/useChat';
import sapphireImage from 'assets/images/sapphire_logo.jpg';

// Import avatars dynamically
const avatarImage = (require as any).context('assets/images/users', true);

// ==============================|| CHAT - USER DETAILS ||============================== //

type Props = {
  onClose?: () => void;
};

const UserDetails = ({ onClose }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { currentUser, activeUser } = useChat();

  const [checked, setChecked] = useState(true);

  if (!currentUser) return <Typography>Loading...</Typography>;

  const {
    firstName,
    lastName,
    type: userRole = 'User Role',
    email: userEmail = 'N/A',
    isLoggedIn: userStatus = false,
    isActive: userVerified = false,
    points: userPoints = 0
  } = currentUser;

  const userName = currentUser?.isAIChat ? 'Sapphire Bot' : `${firstName} ${lastName}`;
  const statusBGColor = currentUser._id in activeUser || currentUser?.isAIChat ? theme.palette.success.lighter : theme.palette.warning.lighter;
  // const statusColor = currentUser._id in activeUser || currentUser?.isAIChat ? theme.palette.success.main : "white";

  const handleOpenMail = () => {
    window.location.href = `mailto:${userEmail}`;
  };

  return (
    <MainCard
      sx={{
        minWidth: matchDownMD ? '400px' : '280px',
        minHeight: matchDownMD ? 'auto' : '94vh',
        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'common.white',
        borderRadius: '0 4px 4px 0',
        borderLeft: 'none'
      }}
      content={false}
    >
      <Box sx={{ p: 3, position: 'relative' }}>
        {onClose && (
          <IconButton size="medium" sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose} color="warning">
            <Add style={{ transform: 'rotate(45deg)' }} />
          </IconButton>
        )}
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <Stack alignItems="center">
              <Avatar
                alt={userName}
                src={
                  currentUser?.isAIChat
                    ? sapphireImage
                    : `data:${currentUser?.userProfilePicId?.mimetype};base64,${currentUser?.userProfilePicId?.file}` || avatarImage
                }
                size="xl"
                sx={{
                  m: '8px auto',
                  width: 88,
                  height: 88,
                  border: '1px solid',
                  borderColor: theme.palette.primary.main,
                  p: 1,
                  bgcolor: 'transparent',
                  '& .MuiAvatar-img': {
                    height: '88px',
                    width: '88px',
                    borderRadius: '50%'
                  }
                }}
              />
              <Typography variant="h5" align="center">
                {userName}
              </Typography>
              <Typography variant="body2" align="center" color="textSecondary">
                {userRole}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="center"
              sx={{ mt: 0.75, '& .MuiChip-root': { height: '24px' } }}
            >
              <Chip
                label={currentUser._id in activeUser || currentUser?.isAIChat ? 'Online' : 'Offline'}
                sx={{
                  bgcolor: statusBGColor,
                  textTransform: 'capitalize',
                  color: "white",
                  '& .MuiChip-label': { px: 1 }
                }}
              />
            </Stack>
          </Grid>
        </Grid>

        {userEmail !== 'N/A' && (
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
            <IconButton size="medium" color="secondary" sx={{ boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.05)' }} onClick={handleOpenMail}>
              <DirectInbox />
            </IconButton>
          </Stack>
        )}
      </Box>
      <Box>
        <SimpleBar
          sx={{
            overflowX: 'hidden',
            height: matchDownLG ? 'auto' : 'calc(100vh - 397px)',
            minHeight: matchDownLG ? 0 : 420
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ px: 3, pb: 3 }}>
              {!currentUser?.isAIChat && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setChecked(!checked)}
                    >
                      <Typography variant="h5">Information</Typography>
                      <IconButton size="small" color="secondary">
                        {checked ? <ArrowUp2 /> : <ArrowDown2 />}
                      </IconButton>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: -1 }}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Collapse in={checked}>
                      <Stack direction="row" justifyContent="space-between" sx={{ mt: 0, display: matchDownMD ? 'flex' : 'block' }}>
                        <Typography>Verified Status</Typography>
                        <Typography color="textSecondary">{userVerified ? 'Verified' : 'Not Verified'}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, display: matchDownMD ? 'flex' : 'block' }}>
                        <Typography>Email</Typography>
                        <Typography color="textSecondary">{userEmail}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, display: matchDownMD ? 'flex' : 'block' }}>
                        <Typography>Points</Typography>
                        <Typography color="textSecondary">{userPoints}</Typography>
                      </Stack>
                    </Collapse>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Stack>
        </SimpleBar>
      </Box>
    </MainCard>
  );
};

export default UserDetails;

import { useLayoutEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// project-imports
import NavGroup from './NavGroup';
import userType from 'menu-items';
import { generateDashboardMenu } from 'menu-items/dashboard';

import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';
import useAuth from 'hooks/useAuth';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>({ items: [] });
  const { user, organization }: any = useAuth();
  const itemPermission = user?.userTypeId?.permission;
  const getDash = generateDashboardMenu(user);
  const itemData = userType?.[user?.type as 'student'];
  const menuItem: any = {
    items: itemData
  };
  function childPermission(item: any) {
    const newChildItems = item?.children?.[0]?.children?.filter((childItem: any) => {
      if (childItem.name === 'CourseReassign') {
        return organization?.trainingMode ? organization?.trainingMode : false;
      }
      return itemPermission?.[item?.name]?.[childItem?.id] === true;
    });
    item.children[0].children = newChildItems;
    return item;
  }
  function CoursePermission(item: any) {
    const newChildItems = item?.children?.[0]?.children?.filter((childItem: any) => {
      if (childItem.name === 'CourseReassign') {
        return organization?.trainingMode ? organization?.trainingMode : false;
      }
      return itemPermission?.[childItem?.name]?.View === true;
    });
    item.children[0].children = newChildItems;
    return item;
  }
  function getPermissionItems(menuItem: any) {
    if (user?.type !== 'superadmin') {
      const keys = Object.keys(itemPermission);
      const newMenuItem = menuItem?.items?.filter((item: any) => {
        if (keys.includes(item?.name)) {
          if (item?.name === 'Courses' || item?.name === 'Exam') {
            return CoursePermission(item);
          }
          if (
            itemPermission?.[item?.name]?.Users === true ||
            itemPermission?.[item?.name]?.Courses === true ||
            itemPermission?.[item?.name]?.Assessments === true
          ) {
            return childPermission(item);
          }
          if (itemPermission?.[item?.name]?.View === true) {
            return true;
          }
        }
        return false;
      });
      newMenuItem?.splice(0, 0, getDash);
      setMenuItems({ items: newMenuItem });
    } else {
      if (menuItem?.items?.[0]?.name !== 'Dashboard') menuItem?.items?.splice(0, 0, getDash);
      setMenuItems(menuItem);
    }
  }
  useLayoutEffect(() => {
    if (menuItem?.items?.length > 0) {
      getPermissionItems(menuItem);
    }
    // eslint-disable-next-line
  }, []);

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems?.items?.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  if (lastItem && lastItem < menuItems?.items?.length) {
    lastItemId = menuItems?.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems?.items?.slice(lastItem - 1, menuItems?.items?.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = menuItems?.items?.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
        // overflowY: { md: 'auto', sm: 'auto', xs: 'auto' },
        // height: { lg: 'auto', md: '90vh', sm: '90vh', xs: '90vh' }
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Bank, Calendar, ClipboardText, NoteText, Notepad2, ShoppingCart, Star1, TaskSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: Notepad2,
  icon2: NoteText,
  icon7: TaskSquare,
  icon3: Calendar,
  icon4: ClipboardText,
  icon5: Bank,
  icon6: Star1,
  icon8: ShoppingCart
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const Exam: NavItemType = {
  id: 'Exam',
  type: 'group',
  name: 'Exam',
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: 'Exam',
      title: <FormattedMessage id="Exam Control" />,
      type: 'collapse',
      children: [
        {
          icon: icons.icon5,
          id: 'question bank',
          title: <FormattedMessage id="Question Bank" />,
          type: 'item',
          name: 'Questions',
          url: '/quiz/questions'
        },
        // {
        //   icon: icons.icon7,
        //   id: 'question group',
        //   title: <FormattedMessage id="Question Group" />,
        //   type: 'item',
        //   name: 'QuestionGroup',
        //   url: '/question-group'
        // },
        {
          icon: icons.icon2,
          id: 'Exam',
          title: <FormattedMessage id="Exam" />,
          type: 'item',
          name: 'Exam',
          url: '/exam'
        },
        {
          icon: icons.icon3,
          id: 'time-table',
          title: <FormattedMessage id="Exam Time-Table" />,
          type: 'item',
          name: 'TimeTable',
          url: '/time-table'
        },
        {
          icon: icons.icon4,
          id: 'Assessment List',
          title: <FormattedMessage id="Assessment List" />,
          type: 'item',
          name: 'Questions',
          url: '/quiz/assessment'
        },
        {
          icon: icons.icon6,
          id: 'contest',
          title: <FormattedMessage id="Contests" />,
          type: 'item',
          name: 'Quiz',
          url: '/contest'
        }
        // {
        //   icon: icons.icon8,
        //   id: 'cart',
        //   title: <FormattedMessage id="Cart" />,
        //   type: 'item',
        //   name: 'Quiz',
        //   url: '/checkout'
        // }
      ]
    }
  ]
};

export default Exam;

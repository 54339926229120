// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ArchiveBook, BookSaved, AddCircle, Gift, Book, Book1, MedalStar, DocumentText, RefreshSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: ArchiveBook,
  icon2: Book,
  icon3: Book1,
  certificate: MedalStar,
  myCourse: BookSaved,
  addIcon: AddCircle,
  offerIcon: Gift,
  assignmentIcon: DocumentText,
  courseReassignIcon: RefreshSquare,
};

// ==============================|| MENU ITEMS - category ||============================== //

const course: NavItemType = {
  id: 'group-course',
  type: 'group',
  icon: icons.icon1,
  name: 'Courses',
  children: [
    {
      icon: icons.icon1,
      id: 'courses',
      title: <FormattedMessage id="Courses" />,
      type: 'collapse',
      children: [
        {
          icon: icons.icon2,
          id: 'course',
          title: <FormattedMessage id="Courses List" />,
          type: 'item',
          name: 'Courses',
          url: '/course'
        },
        {
          icon: icons.icon3,
          id: 'myCourse',
          title: <FormattedMessage id="My Learning" />,
          type: 'item',
          name: 'UserCourses',
          url: '/mylearning'
        },
        {
          icon: icons.offerIcon,
          id: 'offer',
          title: <FormattedMessage id="Offers" />,
          type: 'item',
          name: 'Offers',
          url: '/offer'
        },
        {
          icon: icons.certificate,
          id: 'certificate',
          title: <FormattedMessage id="Certificate Templates" />,
          type: 'item',
          name: 'Certificates',
          url: '/certificate',
          breadcrumbs: false
        },
        {
          icon: icons.assignmentIcon,
          id: 'assignment',
          title: <FormattedMessage id="Assignment" />,
          type: 'item',
          name: 'Assignment',
          url: '/assignment'
        },
        {
          icon: icons.assignmentIcon,
          id: 'CourseReassign',
          title: <FormattedMessage id="Reassign" />,
          type: 'item',
          name: 'CourseReassign',
          url: '/course/reassign'
        },
      ]
    }
  ]
};

export default course;
